<ng-container>
  <div class="profile-stats" *transloco="let t;">
    <ng-container *ngIf="isCreator">
      <!-- <div class="profile-stats-data">
        <span>{{views ?? 0}}</span> views
      </div> -->

<!--      <div class="profile-stats-data" [ngClass]="{ 'active': hideAdditionalStat }" (click)="showFollowers()">-->
<!--        <span>{{followers ?? 0}}</span> followers-->
<!--      </div>-->
      <div class="profile-stats-data">
        <span>{{posts ?? 0}}</span> {{t('posts')}}
      </div>
      <!-- <div class="profile-stats-data">
        <span>{{videos ?? 0}}</span> videos
      </div> -->
<!--      <div class="profile-stats-data" [ngClass]="{ 'active': hideAdditionalStat }" (click)="showFollows()">-->
<!--        <span>{{follows ?? 0}}</span> follows-->
<!--      </div>-->
    </ng-container>
    <ng-container *ngIf="!isCreator">
      <div class="profile-stats-data active" (click)="showFollows()">
        <span>{{follows ?? 0}}</span> {{t('follows')}}
      </div>
    </ng-container>

  </div>
</ng-container>
<app-dialog *ngIf="hideAdditionalStat" #statsDialogRef>
  <app-subscriber-list [subscribers]="listToShow" (onSelect)="routeToProfile($event)"></app-subscriber-list>
</app-dialog>
